import { useSearchParams } from 'next/navigation';

import { Button } from '@swordhealth/ui-corporate';

import { trackEvent } from '@/lib/gtm';

import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

import { scrollToTop } from '@/utils/scrollToSection';

import useReducedMotion from '@/utils/useReducedMotion';
import { createGuardaLink, createOnboardingURL } from '@/utils/onboarding-urls';
import { compareDates } from '@/utils/dates';
import { products } from '@/utils/products';

export const redirectToOnboarding = (
  buttonLabel,
  client,
  buttonLocation,
  href,
  triage,
  trackingLabel,
) => {
  trackEvent('redirect_to_onboarding', {
    value: client.clientKey,
  });

  trackButtonClicked({
    action: triage ? ACTIONS.REDIRECT_TO_TRIAGE : ACTIONS.REDIRECT_TO_ONBOARDING,
    buttonType: BUTTON_TYPES.NAVIGATION,
    buttonText: trackingLabel ?? buttonLabel,
    destination: href,
    buttonLocation: buttonLocation,
  });
};

export default function EnrollButton({
  id,
  isActive,
  buttonLocation,
  openModal,
  client,
  buttonLabel = 'Get started',
  scrollTop,
  product,
  redirectToApp,
  buttonApp,
  triage,
  referralUser,
  isOrganicLP,
  trackingLabel,
  conditionLabel,
  productKey,
}) {
  const searchParams = useSearchParams();
  const isReduced = useReducedMotion();

  if (!isActive) {
    return (
      <Button
        id={id}
        data-testid="enroll-disabled"
        disabled
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonLabel}
      </Button>
    );
  }

  if (compareDates(client?.launchDate)) {
    const params = new URLSearchParams(searchParams?.toString());
    params.append('client', client.clientKey);
    return (
      <Button
        id={id}
        href={'/waiting-list?' + params.toString()}
        data-testid="enroll-soon"
        target="_blank"
        rel="noopener noreferrer"
      >
        Coming Soon
      </Button>
    );
  }

  if (referralUser) {
    const href = createOnboardingURL({
      clientKey: client.institutionKey ?? client.clientKey,
      countryCode: referralUser?.region,
      programType: product?.param,
      query: { ...Object.fromEntries(searchParams), 'referral-token': referralUser?.token },
      triage,
    });

    return (
      <Button
        id={id}
        onClick={() => redirectToOnboarding(buttonLabel, client, buttonLocation, href, triage)}
        href={href}
        data-testid="enroll-active"
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonLabel}
      </Button>
    );
  }

  if (redirectToApp && client?.clientKey) {
    const link = createGuardaLink({
      clientKey: client.institutionKey ?? client.clientKey,
      serviceApp: product?.app,
      programType: product?.param,
    });
    return (
      <Button id={id} href={link} target="_blank" rel="noopener noreferrer">
        {buttonApp?.label ?? product?.enrollLabel ?? 'Enroll Now'}
      </Button>
    );
  }

  if (scrollTop || (redirectToApp && !client.clientKey)) {
    return (
      <Button
        id={id}
        onClick={() => scrollToTop({ isReduced })}
        data-testid="enroll-scroll"
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonLabel}
      </Button>
    );
  }

  if (isOrganicLP) {
    if (!client?.clientKey || !productKey) {
      const params = new URLSearchParams(searchParams?.toString());
      if (client?.clientKey) params.append('client', client.clientKey);
      if (productKey) params.append('solution', productKey);
      const href = `/eligibility-check/?${params}`;

      return (
        <Button
          id={id}
          href={href}
          data-testid="enroll-organic-lp"
          onClick={() => {
            trackButtonClicked({
              action: ACTIONS.REDIRECT_TO_COVERAGE,
              buttonType: BUTTON_TYPES.NAVIGATION,
              buttonText: { buttonLabel },
              destination: { href },
              buttonLocation: buttonLocation,
            });
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {buttonLabel}
        </Button>
      );
    } else {
      const href = createOnboardingURL({
        clientKey: client.institutionKey ?? client.clientKey,
        countryCode: client?.country?.data?.attributes?.ISOCode,
        programType: products[productKey?.toUpperCase()]?.param,
        conditionLabel: conditionLabel,
        query: Object.fromEntries(searchParams),
        triage,
      });

      return (
        <Button
          id={id}
          onClick={() =>
            redirectToOnboarding(buttonLabel, client, buttonLocation, href, triage, trackingLabel)
          }
          href={href}
          data-testid="enroll-active"
          target="_blank"
          rel="noopener noreferrer"
        >
          {buttonLabel}
        </Button>
      );
    }
  }

  if (client?.clientKey && (!client?.countries || client?.countries?.length < 2) && buttonLabel) {
    const href = createOnboardingURL({
      clientKey: client.institutionKey ?? client.clientKey,
      countryCode: client?.country?.data?.attributes?.ISOCode,
      programType: product?.param,
      conditionLabel: conditionLabel,
      query: Object.fromEntries(searchParams),
      triage,
    });
    return (
      <Button
        id={id}
        onClick={() =>
          redirectToOnboarding(
            buttonLabel,
            client,
            buttonLocation,
            client?.loginURL ?? href,
            triage,
            trackingLabel,
          )
        }
        href={client?.loginURL ?? href}
        data-testid="enroll-active"
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonLabel}
      </Button>
    );
  }

  return (
    <Button
      id={id}
      onClick={openModal}
      data-testid="enroll-modal"
      target="_blank"
      rel="noopener noreferrer"
    >
      {buttonLabel}
    </Button>
  );
}
